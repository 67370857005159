import React from 'react';
import Layout from '../components/Layout';

const Four0FourPage = () => (
  <Layout title="Pagina niet gevonden">
    <div className="container py-6 px-5 has-text-centered">
      <h1 className="title">404</h1>
      <h2 className="subtitle">Pagina niet gevonden.</h2>
      <p>De opgegeven pagina kan niet gevonden worden.</p>
    </div>
  </Layout>
);

export default Four0FourPage;
